/* eslint-disable react/prop-types */
import { CheckIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

const Checkbox = ({
  name = "",
  value = "",
  checked = false,
  onChange = () => null,
  onBlur = () => null,
  id = null,
  disabled = false,
  darkMode = false,
  rounded = false,
}) => {
  const className = clsx(
    "Checkbox",
    "relative cursor-pointer flex items-center justify-center",
    {
      "Checkbox--isChecked": checked,
      "Checkbox--isDisabled": disabled,
    }
  );

  return (
    <div className={className}>
      <input
        type="checkbox"
        className={clsx(
          "Checkbox__input",
          "absolute inset-0 z-[999] h-full w-full opacity-0"
        )}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        onBlur={onBlur}
        id={id}
      />
      <div
        className={clsx(
          "group flex h-5 w-5 shrink-0 items-center justify-center rounded-full border-2 transition ease-out",
          {
            "border-zinc-50 bg-zinc-950 hover:bg-zinc-50": !checked && darkMode,
            "border-zinc-950 bg-zinc-50": !checked && !darkMode,
            "border-zinc-50 bg-zinc-50": checked && darkMode,
            "border-zinc-950 bg-zinc-950": checked && !darkMode,
          }
        )}
      >
        {checked && (
          <CheckIcon
            className={clsx("h-3 w-3 stroke-[4] transition ease-out", {
              "stroke-zinc-50 group-hover:stroke-zinc-950":
                !checked && darkMode,
              "stroke-zinc-950 group-hover:stroke-zinc-50":
                !checked && !darkMode,
              "stroke-zinc-950 group-hover:stroke-zinc-950":
                checked && darkMode,
              "stroke-zinc-50 group-hover:stroke-zinc-50": checked && !darkMode,
            })}
          />
        )}
      </div>
    </div>
  );
};

export default Checkbox;
