import * as Accordion from "@radix-ui/react-accordion";
import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";
import Link from "next/link";
import Image from "next/image";
import { useState, useEffect, useContext } from "react";
import { usePreventScroll } from "react-aria";
import { useRouter } from "next/router";
import {
  ArrowRightIcon,
  ArrowLeftIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import { footerNavigation } from "@/lib/navigation";
import useCurrentUser from "@/hooks/useCurrentUser";
import { SessionContext } from "@/contexts/SessionContext";
import { getUserTierFromPlan } from "@/lib/getUserTierFromPlan";
import { getDevice } from "@/lib/helpers";
import pushToDataLayer from "@/lib/pushToDataLayer";
import { pushEvent } from "@/lib/gtm";
import { navItemClick } from "@/gtmEvents/navigation";
import RailContainer from "@/components/RailContainer";
import DraggableScrollArea from "@/components/DraggableScrollArea";
import EntryThumbnailCompact from "@/components/EntryThumbnailCompact";

interface NavBarMobileProps {
  primaryNavigation: Array<any>;
  theme?: string;
  isOpen: boolean;
  setIsOpen?: (value: boolean) => void;
  navigation: Array<any>;
}

const NavBarMobile: React.FC<NavBarMobileProps> = ({
  primaryNavigation = [],
  isOpen,
  setIsOpen = () => {},
  navigation,
}) => {
  const router = useRouter();

  const [currentTab, setCurrentTab] = useState(null); // Track which tab to show in the accordion view
  const [expandedItem, setExpandedItem] = useState<string | null>(null); // Track expanded accordion item
  const { sessionId, guestId, utmString } = useContext(SessionContext);
  const { currentUser } = useCurrentUser();

  usePreventScroll({ isDisabled: !isOpen });

  // Close the menu on route change
  useEffect(() => {
    const handleRouteChange = () => {
      setIsOpen(false);
      setCurrentTab(null); // Reset to the initial state
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => router.events.off("routeChangeComplete", handleRouteChange);
  }, [router.events, setIsOpen]);

  const getEventDataFromNav = (itemName, hierarchy, itemLink, clickType) => {
    return {
      userData: {
        identifier: currentUser ? currentUser.id : guestId,
        userTier: getUserTierFromPlan(currentUser ? currentUser.plan : 0),
        isLoggedIn: !!currentUser,
        firstName: currentUser?.firstName || null,
        lastName: currentUser?.lastName || null,
        email: currentUser?.email || null,
      },
      metaData: {
        eventTimestamp: Date.now(),
        sessionId,
        device: getDevice(),
        platform: "website",
        pagePath: router.asPath,
        referrer: document.referrer,
        utmString,
      },
      eventData: {
        itemName,
        itemHierarchy: hierarchy,
        itemLink,
        clickType,
      },
    };
  };

  const handleNavItemClick = (itemName, hierarchy, itemLink, clickType) => {
    const eventData = getEventDataFromNav(
      itemName,
      hierarchy,
      itemLink,
      clickType
    );

    // Push event to GTM
    pushToDataLayer(navItemClick, eventData);

    // Push event to Mixpanel
    pushEvent("nav_item_click", eventData);
  };

  return (
    <Dialog.Root open={isOpen} modal={false}>
      <Dialog.Portal>
        <Dialog.Content
          className={clsx(
            "MainMenu__content",
            "sticky inset-y-0 top-0 z-[51] flex w-full justify-end"
          )}
        >
          <div className="h-dvh-ex-header relative w-full overflow-y-scroll bg-white shadow-lg sm:w-2/3 md:w-1/2 lg:w-2/5">
            {currentTab === null ? (
              <MainMenu
                primaryNavigation={primaryNavigation}
                setCurrentTab={setCurrentTab}
                navigation={navigation}
              />
            ) : (
              <CurrentTabView
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                expandedItem={expandedItem}
                setExpandedItem={setExpandedItem}
                handleNavItemClick={handleNavItemClick}
              />
            )}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default NavBarMobile;

const RailItem = ({ heading, rail, linkHref, linkLabel, theme = "dark" }) => {
  // Get the current day, expressed as a number
  const currentDay = new Date().getDay();

  return (
    <div
      className={clsx("space-y-4 py-6 lg:space-y-5 lg:py-8", {
        "border-b-zinc-700": theme === "dark",
        "border-b-zinc-200": theme === "light",
      })}
    >
      <div className={clsx("flex items-center px-6 lg:px-12")}>
        <Link
          href={linkHref}
          className={clsx(
            "font-spatial text-lg transition ease-out hover:opacity-75 xl:text-xl"
          )}
        >
          {heading}
        </Link>
      </div>
      <div className={clsx("overflow-hidden")}>
        {rail.typeHandle === "editorialRail" ? (
          <Carousel entries={rail.recipes} theme={theme} />
        ) : (
          <RailContainer
            key={rail.id}
            rail={rail}
            hitsPerPage={24}
            shuffleSeed={currentDay}
            entriesToShow={12}
            renderLoading={() => {
              return <Carousel theme={theme} />;
            }}
            render={(data) => {
              const entries = data.hits;

              return <Carousel entries={entries} theme={theme} />;
            }}
          />
        )}
      </div>
    </div>
  );
};

const Carousel = ({ entries = [], theme = "dark" }) => {
  // if there are no entries, create 12 dummy entries
  if (entries.length === 0) {
    for (let i = 0; i < 12; i++) {
      entries.push({
        id: i,
        title: "",
        summary: "",
        sectionHandle: "loading",
      });
    }
  }

  return (
    <div className={clsx("relative w-full")}>
      <DraggableScrollArea enableSnap={false}>
        <div className={clsx("flex space-x-6 px-6 lg:px-12")}>
          {entries.map((entry, index) => {
            return (
              <div key={entry.id} className={clsx("w-40")}>
                <EntryThumbnailCompact
                  entry={entry}
                  priority={index === 0}
                  draggable={false}
                  selectable={false}
                />
              </div>
            );
          })}
        </div>
      </DraggableScrollArea>
      <div
        className={clsx(
          "pointer-events-none absolute inset-y-0 right-0 z-10 h-full w-16",
          {
            "to-zinc-950/50": theme === "dark",
            "to-white/50": theme === "light",
          }
        )}
      ></div>
    </div>
  );
};

const MainMenu = ({
  primaryNavigation,
  setCurrentTab,
  navigation,
}: {
  primaryNavigation: Array<any>;
  setCurrentTab: (tab: any) => void;
  navigation: Array<any>;
}) => {
  const items = navigation?.mainMenu;

  return (
    <>
      <div className="flex h-full flex-col">
        <div className="flex-1 overflow-y-scroll">
          <ul>
            {primaryNavigation.map((tab) =>
              tab.title === "Our chefs" ? (
                <MainMenuItem
                  key={tab.id}
                  title={tab.title}
                  onClick={() => setCurrentTab(tab)}
                />
              ) : (
                <MainMenuItem
                  key={tab.id}
                  title={tab.title}
                  onClick={() => setCurrentTab(tab)}
                />
              )
            )}
          </ul>
        </div>

        {/* {items.map((item) => {
          if (item.heading === "Recipes") {
            return (
              <RailItem
                key={item.id}
                heading={item.heading}
                rail={item.rail?.[0]}
                linkHref={item.linkHref}
                linkLabel={item.linkLabel}
              />
            );
          }
        })} */}

        <div className="mt-auto">
          <SecondaryNav />
        </div>
      </div>
    </>
  );
};

const MainMenuItem = ({
  title,
  onClick,
}: {
  title: string;
  onClick: () => void;
}) => (
  <li
    className="flex items-center justify-between border-t border-gray-200 px-6 py-4"
    onClick={onClick}
  >
    <span className="font-body text-sm">{title}</span>
    <button className="text-rust" aria-label={`Open ${title}`}>
      <ArrowRightIcon className="h-5 w-5" />
    </button>
  </li>
);

const SecondaryNav = ({ theme = "dark" }) => {
  const { isLoggedOut } = useCurrentUser();

  return (
    <div className={clsx("px-6 py-6 lg:px-12 lg:py-8", {})}>
      <div className={clsx("grid grid-cols-2 gap-x-6 gap-y-2")}>
        {footerNavigation.map((item) => {
          // If the item is marked true for isLoggedOut and the user is logged in, don't show it
          if (!item.isLoggedOut && isLoggedOut) return null;

          return (
            <Link
              key={item.href}
              href={item.href}
              className={clsx(
                "flex font-body text-sm transition ease-out hover:opacity-75",
                {
                  "text-zinc-800": theme === "dark",
                  "text-zinc-950": theme === "light",
                }
              )}
            >
              {item.label}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

const CurrentTabView = ({
  currentTab,
  setCurrentTab,
  expandedItem,
  setExpandedItem,
  handleNavItemClick,
}: {
  currentTab: any;
  setCurrentTab: (tab: null) => void;
  expandedItem: string | null;
  setExpandedItem: (item: string | null) => void;
  handleNavItemClick: (
    title: string,
    hierarchy: string,
    href: string,
    clickType: string
  ) => void;
}) => {
  return (
    <div className="border-t">
      <BackButton
        title={currentTab?.title}
        onBack={() => setCurrentTab(null)}
      />
      {currentTab.title === "Our chefs" ? (
        <ChefsList
          chefs={currentTab.children}
          handleNavItemClick={handleNavItemClick}
          parentTitle={currentTab.title}
        />
      ) : (
        <AccordionMenu
          categories={currentTab.children}
          expandedItem={expandedItem}
          setExpandedItem={setExpandedItem}
          handleNavItemClick={handleNavItemClick}
        />
      )}
    </div>
  );
};

const BackButton = ({
  title,
  onBack,
}: {
  title: string;
  onBack: () => void;
}) => (
  <button
    onClick={onBack}
    className="flex items-center gap-3 px-6 py-4 text-base font-medium"
  >
    <ArrowLeftIcon className="h-5 w-5 text-rust" />
    <div className="font-body text-sm">Back</div>
  </button>
);

const ChefsList = ({
  chefs,
  handleNavItemClick,
  parentTitle,
}: {
  chefs: Array<any>;
  handleNavItemClick: (
    title: string,
    hierarchy: string,
    href: string,
    clickType: string
  ) => void;
  parentTitle: string;
}) => {
  return (
    <div>
      {chefs.map((chef) => {
        return (
          <Link
            key={chef.id}
            href={`/${chef.nodeUri}`}
            className="flex items-center space-x-4 px-6 py-2"
            onClick={() =>
              handleNavItemClick(
                chef.title,
                `${parentTitle}/${chef.title}`,
                `/${chef.nodeUri}`,
                "click"
              )
            }
          >
            {chef.element.image[0]?.url && (
              <div className="relative h-16 w-16">
                <Image
                  src={chef.element.image[0]?.url}
                  alt={chef.title}
                  layout="fill"
                  objectFit="cover"
                  className="rounded-full"
                />
              </div>
            )}
            <div className="font-body text-sm">{chef.title}</div>
          </Link>
        );
      })}
    </div>
  );
};

const AccordionMenu = ({
  categories,
  expandedItem,
  setExpandedItem,
  handleNavItemClick,
}: {
  categories: Array<any>;
  expandedItem: string | null;
  setExpandedItem: (item: string | null) => void;
  handleNavItemClick: (
    title: string,
    hierarchy: string,
    href: string,
    clickType: string
  ) => void;
}) => {
  return (
    <Accordion.Root
      type="single"
      collapsible
      onValueChange={(value) => setExpandedItem(value || null)}
    >
      {categories.map((category) => (
        <Accordion.Item
          key={category.id}
          value={category.title}
          className="border-b border-gray-200"
        >
          <Accordion.Header>
            <Accordion.Trigger className="flex w-full items-center justify-between px-6 py-4 text-base font-medium">
              {category.title}
              <span className="text-rust">
                {expandedItem === category.title ? (
                  <ChevronUpIcon className="h-6 w-6" />
                ) : (
                  <ChevronDownIcon className="h-6 w-6" />
                )}
              </span>
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content className="pl-6 pr-6">
            {category.children?.length > 0 ? (
              <ul className="">
                {category.children.map((child) => (
                  <li key={child.id} className="border-t">
                    <Link
                      href={`/${child.nodeUri}`}
                      className="block py-4 font-body text-sm text-gray-500"
                      onClick={() =>
                        handleNavItemClick(
                          child.title,
                          `${category.title}/${child.title}`,
                          `/${child.nodeUri}`,
                          "click"
                        )
                      }
                    >
                      {child.title}
                    </Link>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="px-6 py-4 text-sm text-gray-500">
                No further categories available.
              </p>
            )}
          </Accordion.Content>
        </Accordion.Item>
      ))}
    </Accordion.Root>
  );
};
