/* eslint-disable react/prop-types */
import clsx from "clsx";
import Link from "next/link";
import React, { useState } from "react";
import Spinner from "./Spinner";

const Button = ({
  id = null,
  hoverIcon = null,
  attributes = {},
  icon = null,
  isDisabled = false,
  isFull = false,
  isSmall = false,
  label = "",
  onClick = () => null,
  theme = "dark",
  type = "button",
  withBorder = false,
  wrapText = false,
  hoverEffect = "none",
  isRounded = true,
  className = "",
  iconClassName = "",
  labelClassName = "",
  href = "",
  children = null,
  isLoading = false,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  let Icon = icon ? icon : null;

  if (children) {
    label = children;
  }

  Icon = isHovering && hoverIcon ? hoverIcon : Icon;

  const classNames = clsx(
    "Button",
    "flex items-center justify-center font-body text-sm transition ease-out text-center white-space-nowrap",
    className,
    {
      "border-[1.5px]": withBorder,
      "border-zinc-950 focus:border-zinc-950 bg-zinc-50 text-zinc-950 hover:bg-zinc-200":
        theme === "dark",
      "border-zinc-950 focus:border-zinc-950 bg-zinc-950 text-zinc-50 hover:bg-zinc-800":
        theme === "light",
      "border-zinc-50 focus:border-zinc-950 bg-zinc-900 text-zinc-50 hover:text-zinc-950 hover:bg-zinc-50":
        theme === "transparent",
      "w-full": isFull,
      "opacity-50 pointer-events-none": isDisabled,
      "h-component-sm px-4": isSmall,
      "pl-3": isSmall && !!Icon,
      "h-component px-6": !isSmall,
      "Button--withBorder": withBorder,
      "Button--wrapText": wrapText,
      "rounded-full": isRounded,
      "space-x-2": !!Icon,
      [`Button--hoverEffect--${hoverEffect}`]: !!hoverEffect,
    }
  );

  const _Content = () => {
    return (
      <div className={clsx("flex items-center justify-center space-x-1")}>
        {Icon && (
          <div
            className={clsx("Button__icon", "flex items-center justify-center")}
          >
            <Icon
              className={clsx(iconClassName, {
                "size-5": !isSmall,
                "size-4.5": isSmall,
              })}
            />
          </div>
        )}
        {isLoading && (
          <div className={clsx("flex items-center justify-center")}>
            <Spinner size="sm" />
          </div>
        )}
        {label && (
          <div
            className={clsx(
              "whitespace-nowrap text-sm font-medium",
              labelClassName
            )}
          >
            {label}
          </div>
        )}
      </div>
    );
  };

  if (!!href) {
    return (
      <Link
        href={href}
        className={classNames}
        onClick={onClick}
        disabled={isDisabled}
        type={type}
        id={id}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        aria-label={label}
        {...attributes}
      >
        <_Content />
      </Link>
    );
  }

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      className={classNames}
      onClick={onClick}
      disabled={isDisabled}
      type={type}
      id={id}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      aria-label={label}
      {...attributes}
    >
      <_Content />
    </button>
  );
};

export default Button;
