import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import AnimatedCross from 'components/AnimatedCross';
import { useRef, useState } from 'react';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#__next');

const Modal = ({
  children,
  headerContent = '',
  heading = '',
  isClosable = true,
  isOpen = false,
  onRequestClose = () => null,
}) => {
  const ref = useRef();

  const handleRequestClose = () => {
    if (isClosable) onRequestClose();
  };

  return (
    <ReactModal
      closeTimeoutMS={250}
      isOpen={isOpen}
      onRequestClose={handleRequestClose}
      className={{
        base: 'Modal__content',
        afterOpen: 'Modal__content--afterOpen',
        beforeClose: 'Modal__content--beforeClose',
      }}
      overlayClassName={{
        base: 'Modal__overlay',
        afterOpen: 'Modal__overlay--afterOpen',
        beforeClose: 'Modal__overlay--beforeClose',
      }}
      portalClassName="Modal__portal"
      bodyOpenClassName="Body--modalOpen"
      htmlOpenClassName="Html--modalOpen"
      contentRef={(contentRef) => {
        ref.current = contentRef;
      }}
      onAfterOpen={() => {
        disableBodyScroll(ref.current);
      }}
      onAfterClose={() => {
        clearAllBodyScrollLocks();
      }}
    >
      {isClosable && <CloseButton onRequestClose={onRequestClose} />}
      {(heading || headerContent) && (
        <div className="Modal__header">
          {heading && <h2 className="Modal__heading">{heading}</h2>}
          {headerContent && (
            <div className="Modal__headerContent">{headerContent}</div>
          )}
        </div>
      )}
      <div className="Modal__children">{children}</div>
    </ReactModal>
  );
};

const CloseButton = ({ onRequestClose }) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <button
      className="Modal__close"
      type="button"
      onClick={onRequestClose}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      aria-label="Close modal"
    >
      <AnimatedCross isActive={isHovering} />
    </button>
  );
};

export default Modal;
