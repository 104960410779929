import clsx from "clsx";

const Form = ({ handleReset, handleSubmit, children, actions = [], attributes = {} }) => (
  <form
    className={clsx("Form", "space-y-4")}
    onReset={handleReset}
    onSubmit={handleSubmit}
    {...attributes}
  >
    <div className={clsx("Form__content", "space-y-4")}>{children}</div>
    {actions && actions.length > 0 && (
      <div className={clsx("Form__actions", "space-y-2")}>
        {actions.map((action, _i) => (
          <div key={_i} className="Form__action">
            {action}
          </div>
        ))}
      </div>
    )}
  </form>
);

export default Form;
