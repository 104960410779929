import { useAdContext } from "@/contexts/AdContext";
import AdBlockerMessage from "@/src/ads/components/AdBlockerMessage";
import useDetectAdBlock from "@/src/ads/hooks/useDetectAdBlock";
import FreestarAdSlot from "@freestar/pubfig-adslot-react-component";
import AdSpot from "./AdSpot";

type HashtagAdSpot =
  | "htlad-aggregation_scroll"
  | "htlad-article_left"
  | "htlad-article_right"
  | "htlad-recipe_ingedients"
  | "htlad-recipe_method"
  | "htlad-ros_1x1"
  | "htlad-ros_adhesion"
  | "htlad-ros_postarticle"
  | "htlad-ros_top";

type FreestarAdSpot =
  | "mob.co.uk_medrec_3" // x
  | "mob.co.uk_medrec_5" // x
  | "mob.co.uk_medrec_2" // x
  | "mob.co.uk_medrec_1" // x
  | "mob.co.uk_leaderboard_3" // home builder and recipe
  | "mob.co.uk_sidewall_right" // x
  | "mob.co.uk_leaderboard_8" // home builder
  | "mob.co.uk_medrec_7" // x
  | "mob.co.uk_medrec_10" // x
  | "mob.co.uk_leaderboard_2" // home builder
  | "mob.co.uk_leaderboard_6" // x
  | "mob.co.uk_leaderboard_7" // x
  | "mob.co.uk_leaderboard_4" // x
  | "mob.co.uk_leaderboard_5" // x
  | "mob.co.uk_leaderboard_10" // x
  | "mob.co.uk_medrec_6" // x
  | "mob.co.uk_medrec_4" // x
  | "mob.co.uk_square" // recipe steps and article body
  | "mob.co.uk_medrec_9" // x
  | "mob.co.uk_sidewall_left" // article sidebar
  | "mob.co.uk_leaderboard_9" // x
  | "mob.co.uk_medrec_8"; // x

type AdProps = {
  placementName: HashtagAdSpot | FreestarAdSpot;
  className?: string;
  targeting?: Record<string, unknown>;
};

const Ad = ({ placementName, className = "", targeting = {} }: AdProps) => {
  const ads = useAdContext();
  const adBlockDetected = useDetectAdBlock();

  if (!ads.showAds) return null;

  // Determine which ad provider to use
  const adProvider = process.env.NEXT_PUBLIC_ADS_PROVIDER; // "freestar" or "hashtaglabs"

  if (adBlockDetected && adProvider)
    return (
      <div className={className}>
        <AdBlockerMessage />
      </div>
    );

  // Work out what provide the ad slot is
  const isFreestarPlacementName = placementName.startsWith("mob.co.uk_");

  if (adProvider === "freestar") {
    return (
      <FreestarAdSlot
        publisher="mob-co-uk"
        placementName={placementName}
        targeting={targeting}
        classList={[
          "Ad",
          "flex",
          "items-center",
          "justify-center",
          "[&>div:not(:empty)]:my-8",
          "print:!hidden",
          className,
        ]}
      />
    );
  }

  if (adProvider === "hashtaglabs") {
    // If the provider is hashtaglabs, but the slot is a freestar slot, we need to convert it
    if (isFreestarPlacementName) {
      const hashtagLabsPlacementName = freestarToHashtagLabsPlacementName(
        placementName as string
      );
      return (
        <div className={className}>
          <AdSpot spot={hashtagLabsPlacementName} />
        </div>
      );
    }

    return (
      <div className={className}>
        <AdSpot spot={placementName} />
      </div>
    );
  }

  return null;
};

// Convert freestar placement names to hashtaglabs placement names.
const freestarToHashtagLabsPlacementName = (freestarPlacementName: string) => {
  if (freestarPlacementName.startsWith("mob.co.uk_leaderboard_")) {
    return "htlad-aggregation_scroll";
  }
  if (freestarPlacementName.startsWith("mob.co.uk_medrec_")) {
    return "htlad-article_right";
  }
  if (freestarPlacementName === "mob.co.uk_sidewall_right") {
    return "htlad-article_right";
  }
  if (freestarPlacementName === "mob.co.uk_sidewall_left") {
    return "htlad-article_left";
  }
  // Default case for any other conversions
  return freestarPlacementName.replace("mob.co.uk_", "htlad-");
};

export default Ad;
