/* eslint-disable react/prop-types */
import { useQuery } from "@apollo/react-hooks";
import ErrorMessage from "components/ErrorMessage";
import InlineLoading from "components/InlineLoading";
import { withApollo } from "lib/apollo";
import QueryCategories from "queries/QueryCategories.graphql";

const CategoriesByGroup = ({ groupId = null, render = null, loadingComponent = null }) => {
  const { loading, error, data } = useQuery(QueryCategories, {
    variables: {
      groupId,
    },
  });

  if (loading) return loadingComponent || <InlineLoading />;
  if (error) return <ErrorMessage error={error} />;

  const { categories } = data;

  const output = render(categories);

  return <>{categories && output}</>;
};

export default withApollo({ ssr: true })(CategoriesByGroup);
