import clsx from "clsx";
import { Check, Cross, Plus, Rotate } from "components/Icons";
import { AnimatePresence, motion } from "framer-motion";
import { useStores } from "hooks/useStores";
import { defaultContainerVariants } from "lib/constants";
import { observer } from "mobx-react";
import React, { useRef, useState } from "react";

const MenuList = ({ recipe }) => (
  <div className="MenuList">
    <Menus recipe={recipe} />
    <Create recipe={recipe} />
  </div>
);

const Menus = observer(({ recipe }) => {
  const { menuStore } = useStores();

  const isInDefault = menuStore.defaultMenu.isInMenu(recipe);

  return (
    <ul
      className={clsx(
        "MenuList__menus",
        "m-0 max-h-60 overflow-y-auto p-0 scrollbar scrollbar-thumb-white/20 scrollbar-w-1"
      )}
    >
      <AnimatePresence initial={false}>
        {menuStore.doesDefaultMenuExist && (
          <Menu
            key={menuStore.defaultMenu.id}
            menu={menuStore.defaultMenu}
            isActive={isInDefault}
            recipe={recipe}
          />
        )}
        {menuStore.nonDefaultMenus.map((menu) => {
          const isActive = menu.isInMenu(recipe);

          return (
            <Menu
              key={menu.id}
              menu={menu}
              isActive={isActive}
              recipe={recipe}
            />
          );
        })}
      </AnimatePresence>
    </ul>
  );
});

const Menu = observer(({ menu, isActive, recipe }) => {
  const ref = useRef(null);
  const [isHovering, setIsHovering] = useState(false);

  const handleClick = () => {
    // Add this recipe to the menu's recipes list
    menu.toggleRecipe(recipe);
  };

  const className = clsx("MenuList__menu", {
    "MenuList__menu--isHovering": isHovering,
    "MenuList__menu--isActive": isActive,
  });

  let icon;

  if (isActive && isHovering) {
    icon = <Cross />;
  } else if (isActive && !isHovering) {
    icon = <Check />;
  } else if (!isActive && isHovering) {
    icon = <Plus />;
  }

  return (
    <motion.li
      className={className}
      ref={ref}
      variants={defaultContainerVariants}
      initial="hidden"
      animate="show"
      exit="hidden"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <button
        className={clsx("MenuList__toggle", "flex h-12 w-full items-center")}
        type="button"
        onClick={handleClick}
        data-recipe-id={recipe.id}
        data-recipe-title={recipe.title}
        data-menu-id={menu.id}
        data-menu-title={menu.title}
        aria-label={
          isActive
            ? `Add ${recipe.title} to ${menu.title}`
            : `Remove ${recipe.title} recipe from ${menu.title}`
        }
      >
        <div className={clsx("MenuList__label", "px-4")}>{menu.title}</div>
        <div
          className={clsx(
            "MenuList__status",
            "ml-auto flex h-12 w-12 items-center justify-center"
          )}
        >
          {icon}
        </div>
      </button>
    </motion.li>
  );
});

const Create = observer(({ recipe }) => {
  const { menuStore } = useStores();

  const [menuTitle, setMenuTitle] = useState("");

  const handleChange = (ev) => {
    setMenuTitle(ev.target.value);
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();

    // Don't try to save if no title added
    if (!menuTitle) return false;

    menuStore.create(menuTitle, recipe);

    setMenuTitle("");
  };

  const className = clsx("MenuList__create", "border-t border-white/25", {
    "MenuList__create--isValid": menuTitle !== "",
  });

  return (
    <div className={className}>
      <form
        onSubmit={handleSubmit}
        className={clsx("MenuList__form", "flex h-12 w-full items-center")}
      >
        <input
          type="text"
          placeholder="Create a new collection..."
          value={menuTitle}
          onChange={handleChange}
          className={clsx("MenuList__input", "px-4")}
        />
        <button
          type="submit"
          className={clsx(
            "MenuList__submit",
            "flex h-12 w-12 items-center justify-center",
            {
              "opacity-50": menuTitle === "",
            }
          )}
        >
          {menuStore.isSaving ? <Rotate /> : <Plus />}
        </button>
      </form>
    </div>
  );
});

export default MenuList;
