import Link from "next/link";
import Image from "next/image";
import cloudflareLoader from "@/lib/cloudflare-images";
import pushToDataLayer from "@/lib/pushToDataLayer";
import { pushEvent } from "@/lib/gtm";
import useCurrentUser from "@/hooks/useCurrentUser";
import { getUserTierFromPlan } from "@/lib/getUserTierFromPlan";
import { useContext, useState } from "react";
import { SessionContext } from "@/contexts/SessionContext";
import { getDevice } from "@/lib/helpers";
import { useRouter } from "next/router";
import { navItemClick } from "@/gtmEvents/navigation";
import clsx from "clsx";

const hoverColors = [
  "group-hover:border-rust",
  "group-hover:border-violet",
  "group-hover:border-acid",
  "group-hover:border-rose",
];

const NavBarDesktop = ({ primaryNavigation }) => {
  const { currentUser } = useCurrentUser();
  const { sessionId, guestId, utmString } = useContext(SessionContext);
  const router = useRouter();
  const [hoveredTab, setHoveredTab] = useState(null);

  const getEventDataFromNav = (itemName, hierarchy, itemLink, clickType) => {
    return {
      userData: {
        identifier: currentUser ? currentUser.id : guestId,
        userTier: getUserTierFromPlan(currentUser ? currentUser.plan : 0),
        isLoggedIn: !!currentUser,
        firstName: currentUser?.firstName || null,
        lastName: currentUser?.lastName || null,
        email: currentUser?.email || null,
      },
      metaData: {
        eventTimestamp: Date.now(),
        sessionId,
        device: getDevice(),
        platform: "website",
        pagePath: router.asPath,
        referrer: document.referrer,
        utmString,
      },
      eventData: {
        itemName,
        itemHierarchy: hierarchy,
        itemLink,
        clickType,
      },
    };
  };

  const handleNavItemClick = (itemName, hierarchy, itemLink, clickType) => {
    const eventData = getEventDataFromNav(
      itemName,
      hierarchy,
      itemLink,
      clickType
    );

    // Push event to GTM
    pushToDataLayer(navItemClick, eventData);

    // Push event to Mixpanel
    pushEvent("nav_item_click", eventData);

    setHoveredTab(null);
  };

  return (
    <div className="invisible flex items-center md:visible">
      <nav aria-label="Main Navigation">
        <div>
          <ul className="relative z-50 flex pl-8">
            {Array.isArray(primaryNavigation) &&
              primaryNavigation.map((tab, index) => (
                <li
                  key={tab.title}
                  className="group relative"
                  onMouseEnter={() => setHoveredTab(index)}
                  onMouseLeave={() => setHoveredTab(null)}
                >
                  <button
                    className={clsx(
                      "px-4 pb-4 text-base font-medium",
                      "group-hover:hoverColors[index % hoverColors.length]"
                    )}
                    aria-expanded={hoveredTab === index}
                    aria-controls={`dropdown-${index}`}
                  >
                    <span
                      className={clsx(
                        "border-b-4 border-transparent pb-1",
                        hoverColors[index % hoverColors.length]
                      )}
                    >
                      {tab.title}
                    </span>
                  </button>

                  <div
                    id={`dropdown-${index}`}
                    className={clsx(
                      "fixed inset-x-0 top-full z-50 w-full bg-white p-6 shadow-sm shadow-gray-200 transition-all",
                      hoveredTab === index ? "block" : "hidden"
                    )}
                  >
                    <div className="max-w-screen-xl">
                      {tab.title === "Our chefs" ? (
                        <div className="ml-6 grid grid-cols-3 gap-y-2">
                          {tab.children?.map((chef) => (
                            <div
                              key={chef.id}
                              className="flex items-center py-2"
                            >
                              {chef.element?.image?.length > 0 && (
                                <Link
                                  href={`/${chef.nodeUri}`}
                                  onClick={() =>
                                    handleNavItemClick(
                                      chef.title,
                                      `${tab.title}/${chef.title}`,
                                      `/${chef.nodeUri}`,
                                      "click"
                                    )
                                  }
                                >
                                  <div className="flex items-center space-x-4">
                                    <div className="flex h-16 w-16 items-center justify-center overflow-hidden rounded-full">
                                      <Image
                                        src={chef.element.image[0]?.url}
                                        alt={chef.title}
                                        width={64}
                                        height={64}
                                        className="h-full w-full object-cover"
                                        loader={cloudflareLoader}
                                        priority
                                      />
                                    </div>
                                    <div className="whitespace-nowrap font-body text-sm text-gray-800">
                                      {chef.title}
                                    </div>
                                  </div>
                                </Link>
                              )}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="ml-6 grid grid-cols-4 gap-x-4">
                          {tab.children?.map((category) => (
                            <div key={category.id} className="">
                              <h3 className="font-semibold text-gray-800">
                                {category.title}
                              </h3>
                              <ul className="space-y-1 pb-4">
                                {category.children?.map((link) => (
                                  <li key={link.id}>
                                    <Link
                                      href={`/${link.nodeUri}`}
                                      className="font-body text-sm text-gray-500 hover:underline"
                                      onClick={() =>
                                        handleNavItemClick(
                                          link.title,
                                          `${tab.title}/${category.title}/${link.title}`,
                                          `/${link.nodeUri}`,
                                          "click"
                                        )
                                      }
                                    >
                                      {link.title}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default NavBarDesktop;
