import Layout from "@/components/Layout";
import SessionManager from "@/components/SessionManager";
import { AdContextProvider } from "@/contexts/AdContext";
import { CollectionsContextProvider } from "@/contexts/CollectionsContext";
import GeoPreferenceContextProvider from "@/contexts/GeoPreferenceContext";
import { HeaderContextProvider } from "@/contexts/HeaderContext";
import { MenuContextProvider } from "@/contexts/MenuContext";
import { NavigationContextProvider } from "@/contexts/NavigationContext";
import { NotificationsContextProvider } from "@/contexts/NotificationsContext";
import { PaywallContextProvider } from "@/contexts/PaywallContext";
import { PlannerContextProvider } from "@/contexts/PlannerContext";
import { SearchContextProvider } from "@/contexts/SearchContext";
import { SessionProvider } from "@/contexts/SessionContext";
import { SubscriptionContextProvider } from "@/contexts/SubscriptionContext";
import DidomiConsentManager from "@/src/components/DidomiConsentManager";
import JsonLdSchema from "@/src/components/JsonLdSchema";
import { HashtagLabsProvider } from "@/src/contexts/HashtagLabsContext";
import { HomeHeroContextProvider } from "@/src/contexts/HomeHeroContext";
import useFathom from "@/src/hooks/useFathom";
import useIntercom from "@/src/hooks/useIntercom";
import useSetPreviousUrl from "@/src/hooks/useSetPreviousUrl";
import "@/styles/globals.css";
import "@/styles/index.scss";
import * as Tooltip from "@radix-ui/react-tooltip";
import { observer } from "mobx-react";
import Head from "next/head";
import Script from "next/script";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect } from "react";
import { Seomatic } from "react-seomatic";
import { SWRConfig } from "swr";
import { YbugProvider } from "ybug-react";

function MyApp({ Component, pageProps }) {
  const {
    navigation = {},
    primaryNavigation = {},
    seomatic = {},
    jsonLdSchema = undefined,
    promoBanner,
  } = pageProps;

  /*
   * Bootstrap various third party services
   */

  // Intercom
  useIntercom();

  // Fathom
  useFathom();

  /*
   * Init internal hooks
   */

  // Save the previous URL to aid with redirecting after logins
  useSetPreviousUrl();

  // Use the layout defined by the page, or default to the standard layout
  const getLayout =
    Component.getLayout ||
    ((page) => (
      <Layout
        navigation={navigation}
        primaryNavigation={primaryNavigation}
        promoBanner={promoBanner}
      >
        {page}
      </Layout>
    ));

  useEffect(() => {
    if (typeof window !== "undefined") {
      posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
        api_host:
          process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://eu.posthog.com",
        ui_host: "https://eu.posthog.com",
        autocapture: false,
        capture_pageview: false,
        capture_pageleave: false,
        persistence: "localStorage+cookie",
        disable_web_experiments: false,
      });

      if (process.env.NODE_ENV !== "production") {
        posthog.debug(true);
      }
    }
  }, []);

  return (
    <PostHogProvider client={posthog}>
      <SWRConfig
        value={{
          fetcher: (url) => fetch(url).then((r) => r.json()),
        }}
      >
        <Script
          src={
            process.env.NEXT_PUBLIC_VERCEL_ENV === "production"
              ? "//htlbid.com/v3/mob.co.uk/htlbid.js"
              : "//htlbid.com/stage/v3/mob.co.uk/htlbid.js"
          }
        />

        <Head>
          <meta
            name="viewport"
            content="height=device-height, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0"
          ></meta>
          <meta name="theme-color" content="#000" />
        </Head>

        {(process.env.NEXT_PUBLIC_VERCEL_ENV === "production" ||
          process.env.NEXT_PUBLIC_VERCEL_ENV === "preview") && (
          <Script src="//cdn-4.convertexperiments.com/v1/js/100413104-100414146.js" />
        )}

        <Script src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=R7PWNv" />

        <DidomiConsentManager />

        <Script src="//www.instagram.com/embed.js" />

        <Seomatic Head={Head} {...seomatic} />

        {!!jsonLdSchema && <JsonLdSchema schema={jsonLdSchema} />}

        <YbugProvider
          ybugId="4tdtt7rpsysnnq3r3cm8"
          settings={{
            hide_launcher: true,
          }}
        >
          <Tooltip.Provider delayDuration={0}>
            <HomeHeroContextProvider>
              <HashtagLabsProvider>
                <MenuContextProvider>
                  <CollectionsContextProvider>
                    <GeoPreferenceContextProvider>
                      <PlannerContextProvider>
                        <SubscriptionContextProvider>
                          <NotificationsContextProvider>
                            <SearchContextProvider>
                              <HeaderContextProvider>
                                <NavigationContextProvider>
                                  <PaywallContextProvider>
                                    <AdContextProvider>
                                      <SessionProvider>
                                        <SessionManager />
                                        {getLayout(
                                          <Component {...pageProps} />
                                        )}
                                      </SessionProvider>
                                    </AdContextProvider>
                                  </PaywallContextProvider>
                                </NavigationContextProvider>
                              </HeaderContextProvider>
                            </SearchContextProvider>
                          </NotificationsContextProvider>
                        </SubscriptionContextProvider>
                      </PlannerContextProvider>
                    </GeoPreferenceContextProvider>
                  </CollectionsContextProvider>
                </MenuContextProvider>
              </HashtagLabsProvider>
            </HomeHeroContextProvider>
          </Tooltip.Provider>
        </YbugProvider>
      </SWRConfig>
    </PostHogProvider>
  );
}

export default observer(MyApp);
