import { ChevronDownIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useState } from "react";

const FaqAccordionList = ({ faqs }) => {
  const [openItems, setOpenItems] = useState([]);

  const handleToggle = (id) => {
    setOpenItems((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  return (
    <div>
      {faqs.map((faq) => (
        <AccordionItem
          key={faq.key}
          id={faq.key}
          title={faq.question}
          isOpen={openItems.includes(faq.key)}
          onToggle={() => handleToggle(faq.key)}
        >
          <div
            className="body-text-sm"
            dangerouslySetInnerHTML={{ __html: faq.answer }}
          />
        </AccordionItem>
      ))}
    </div>
  );
};

const AccordionItem = ({ id, title, children, isOpen, onToggle }) => {
  return (
    <div className="border-b pb-4">
      <button
        className="flex w-full justify-between gap-2 pt-4 transition ease-out hover:opacity-75"
        onClick={onToggle}
        aria-expanded={isOpen}
      >
        <span className="text-left text-base">{title}</span>
        <ChevronDownIcon
          className={clsx(
            "block h-5 w-5 flex-shrink-0 transition duration-300 ease-out",
            {
              "rotate-180": isOpen,
            }
          )}
        />
      </button>
      <div
        className={clsx(
          "overflow-hidden transition-all duration-300 ease-out",
          isOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
        )}
      >
        <div className="pt-4">{children}</div>
      </div>
    </div>
  );
};

export default FaqAccordionList;
